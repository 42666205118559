import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Slide from '../slide/Slide'
import './slider.css'
import 'swiper/css'

const PASS = [
  { title: 'ACCESS TO A CLOSED DISCORD CHANNEL', imageUrl: './images/card_1.png', imageUrlAlt: './images/card_1.webp', text: 'The Resident Gold role allows you to get early access to all updates' },
  { title: 'RESIDENT GOLD ROLE', imageUrl: './images/card_2.png', imageUrlAlt: './images/card_2.webp', text: 'You will get the opportunity to get a unique first collection of clothes from DOM WORLD' },
  { title: 'UNIQUE CLOTHING DROPS', imageUrl: './images/card_3.png', imageUrlAlt: './images/card_3.webp', text: 'You will have a unique opportunity to conduct DOM WORLD tests together with developers' },
  { title: 'METAVERSE DEMO ACCESS', imageUrl: './images/card_4.png', imageUrlAlt: './images/card_4.webp', text: 'You will be invited to the closed DOM WORLD channel, where you can communicate with all founders of the company.' },
]

const IRL = [
  { title: 'DOM WORLD ID CARD', imageUrl: './images/card_5.png', imageUrlAlt: './images/card_5.webp' },
  { title: 'PHYGITAL  EVENTS', imageUrl: './images/card_6.png', imageUrlAlt: './images/card_6.webp' },
  { title: 'PHYGITAL DROPS', imageUrl: './images/card_4.png', imageUrlAlt: './images/card_4.webp' },
]

const Slider = (props) => {
  return (
    <div className='errpr__container' {...props}>
      <div>
        <Swiper spaceBetween={0} slidesPerView={1.25} centeredSlides={true} loop={true} navigation={{ nextEl: '#nextC', prevEl: '#prevC' }} style={{ width: 'auto', height: 'auto' }}>
          {PASS.map((slide, i) => (
            <SwiperSlide key={i}>
              <Slide class='onetile' title={slide.title} image={slide.imageUrlAlt} imageAlt={slide.imageUrlAlt}>
                <p>{slide.text}</p>
              </Slide>
            </SwiperSlide>
          ))}
          {IRL.map((slide, i) => (
            <SwiperSlide key={i}>
              <Slide class='onetile' title={slide.title} image={slide.imageUrlAlt} imageAlt={slide.imageUrlAlt}>
                <p>{slide.text}</p>
              </Slide>
            </SwiperSlide>
          ))}

          {/* <SwiperSlide>
          <Slide class='onetile' title='metaverse dom' image={dom}>
            <p>DOM WORLD is a&nbsp;virtual community space, the&nbsp;foundation of&nbsp;which is&nbsp;to&nbsp;give the creative community an opportunity to&nbsp;interact and&nbsp;discover new realms and domains in&nbsp;an&nbsp;environment that boosts creativity and&nbsp;freedom.</p>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide class='onetile' title='metaverse dom' image={dom}>
            <p>DOM WORL is a&nbsp;virtual community space, the&nbsp;foundation of&nbsp;which is&nbsp;to&nbsp;give the creative community an opportunity to&nbsp;interact and&nbsp;discover new realms and domains in&nbsp;an&nbsp;environment that boosts creativity and&nbsp;freedom.</p>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide class='onetile' title='metaverse dom' image={dom}>
            <p>DOM WORLD is a&nbsp;virtual community space, the&nbsp;foundation of&nbsp;which is&nbsp;to&nbsp;give the creative community an opportunity to&nbsp;interact and&nbsp;discover new realms and domains in&nbsp;an&nbsp;environment that boosts creativity and&nbsp;freedom.</p>
          </Slide>
        </SwiperSlide> */}
        </Swiper>
      </div>
      <div className='errp-btn-container'>
        <button className='mint-btn errpr-btn'>MINT NFT</button>
      </div>
    </div>
  )
}

export default Slider
