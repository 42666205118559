import React from 'react'
import './mobile-menu.css'

const socials = [
  { title: 'instagram', url: './images/inst.svg' },
  { title: 'twitter', url: './images/twitter.svg' },
  { title: 'discord', url: './images/discord.svg' },
  { title: 'linkedin', url: './images/linkedin.svg' },
]

const MobileMenu = (props) => {
  let className = 'mobile-menu__container section__padding ' + props.className

  return (
    <div className={className}>
      {/* <img src='./images/contact_bg.png' alt='' /> */}
      <div className='mobile-menu__content'>
        <h2 className='mobile-menu__title'>Contact</h2>
        <div className='mobile-menu-line'></div>
        <div className='mobile-menu-text'>email@gmail.com</div>
        <div className='mobile-menu__socials'>
          {socials.map((item, i) => (
            <img key={i + 'n'} src={item.url} alt={item.title} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
