import React, { useState } from 'react'
import './button.css'

const Button = (props) => {
  const [pushed, setPushed] = useState(false)
  return (
    <button className='mint-btn' {...props}>
      {props.title}
    </button>
  )
}

export default Button
