import * as React from 'react'
import { memo } from 'react'
import './temp-logo.css'

const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='205' height='179' viewBox='0 0 205 179' fill='none' className='temp__logo' {...props}>
    <path
      fill='#fff'
      d='M108.6.7c3 .2 6 .4 9 .8 17.8 1.8 34.8 6.5 50 16.2 20 12.7 31.6 31 35.5 54.2 2.6 16 2 32-2.9 47.6a77 77 0 0 1-41 47c-11.6 6-24 9.3-36.9 11l-3.3.4v-28.8c0-1.2.4-1.6 1.5-1.8a51 51 0 0 0 42-40.6c3-13.2 2.8-26.3-1.4-39.1a50 50 0 0 0-36.4-34.3 86.3 86.3 0 0 0-54 3.5A48.8 48.8 0 0 0 44 67.6a72.3 72.3 0 0 0 .1 44.5A49.8 49.8 0 0 0 85 147.3c1.1.2 1.5.6 1.5 1.8V177l-.1 1c-3.8-.5-7.6-1-11.3-1.7-13.2-2.6-25.8-7-37.3-14.2a76.6 76.6 0 0 1-36-54.5c-2.6-15.9-2-31.6 2.7-47a76.8 76.8 0 0 1 40.3-47.2A113 113 0 0 1 81.5 2.2c4.7-.6 9.4-.9 14.1-1.3l1-.2h12Z'
    />
  </svg>
  // <svg width='205' height='179' viewBox='0 0 205 179' fill='none' xmlns='http://www.w3.org/2000/svg' className='temp__logo' {...props}>
  //   <path
  //     d='M108.608 0.686523C111.598 0.931866 114.596 1.13887 117.578 1.45322C135.411 3.30863 152.355 7.96252 167.605 17.6919C187.562 30.4114 199.224 48.6741 203.065 71.9127C205.71 87.9443 205.089 103.884 200.22 119.479C193.466 141.122 179.335 156.472 159.256 166.577C147.617 172.434 135.212 175.785 122.347 177.54C121.319 177.678 120.284 177.786 119.05 177.939C119.05 177.356 119.05 176.881 119.05 176.406C119.05 167.305 119.081 158.204 119.019 149.104C119.012 147.9 119.357 147.494 120.53 147.256C130.014 145.324 138.678 141.59 146.061 135.211C154.855 127.621 160.084 117.899 162.56 106.675C165.466 93.5489 165.274 80.4383 161.142 67.5962C155.33 49.5404 142.841 38.3467 124.708 33.2942C114.611 30.488 104.299 30.0127 93.9024 30.9097C85.8521 31.6074 78.0702 33.3862 70.7329 36.8363C57.3387 43.1615 48.4297 53.512 44.0365 67.5655C39.4133 82.3474 39.3903 97.3056 44.1209 112.057C49.8481 129.975 62.2839 141.076 80.2476 146.19C81.8117 146.635 83.391 147.049 85.0011 147.31C86.1128 147.494 86.4732 147.908 86.4655 149.073C86.4118 157.583 86.4348 166.109 86.4348 174.619C86.4348 175.409 86.4425 176.206 86.4271 177.004C86.4195 177.326 86.4042 177.563 86.3812 178.062C82.6014 177.456 78.8138 176.965 75.1414 176.26C61.8698 173.707 49.2807 169.314 37.7956 162.053C17.6315 149.303 5.78594 131.002 1.87579 107.572C-0.769313 91.7471 -0.20959 76.0222 4.49793 60.6116C11.0685 39.0597 24.9764 23.6721 44.8338 13.4444C56.3803 7.4948 68.7011 3.97569 81.5202 2.21229C86.1894 1.56826 90.9046 1.29991 95.6045 0.862895C95.9341 0.832228 96.2562 0.740192 96.5782 0.686523C100.588 0.686523 104.606 0.686523 108.608 0.686523Z'
  //     fill='white'
  //   />
  // </svg>
)

const TempLogo = memo(SvgComponent)
export default TempLogo
