import React from 'react'
import { Waves, TempLogo } from '../../components'
import styles from './temporary.module.css'

const Temporary = (props) => {
  const clipStyle = {
    cursor: 'url("./images/cursor.svg"),pointer',
  }
  const lines = Array.from(document.querySelectorAll('.animation > path'))
  const animateHandler = () => {
    lines.forEach((item) => {
      if (item.getAttribute('opacity') === '0') {
        item.setAttribute('opacity', 1)
      } else {
        item.setAttribute('opacity', 0)
      }
      console.log('click', item.getAttribute('opacity'))
    })
  }

  return (
    <div className={`${styles.tempContainer} section__padding`}>
      <div className={styles.tempLogoBlock}>
        <Waves className='animation' />
        <TempLogo className={`temp__logo`} onClick={animateHandler} />
        <div className={styles.tempDescContainer}>
          <p className={`${styles.tempDesc} ${styles.tempText}`}>DOM WORLD&nbsp;is a&nbsp;phygital ecosystem the foundation of&nbsp;which is&nbsp;to&nbsp;give the creative community an&nbsp;opportunity to&nbsp;interact and discover new realms and domains in&nbsp;an&nbsp;environment that boosts creativity and freedom.</p>
          <p className={`${styles.tempDesc} ${styles.tempText}`}>Where Users, Brands and Creators will be&nbsp;able to&nbsp;unite for creativity, collaborations and partnerships.</p>
          {props.children}
        </div>
        <div className={styles.tempFooter}>
          <div className={styles.tempFooterLeft}>
            <p className={styles.tempText}>MIND WORKING</p>
            <div className={styles.tempDotsContainer}>
              <div className={styles.tempDots}></div>
            </div>
          </div>
          <div className={styles.tempFooterRight}>
            <p className={styles.tempTextSecondary}>Los angeles, Miami, London, Bali, Dubai</p>
            <p
              className={`${styles.tempTextClip} ${styles.tempText}`}
              style={clipStyle}
              onClick={() => {
                navigator.clipboard.writeText('welcome@domworld.io')
              }}
            >
              welcome@domworld.io
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Temporary
