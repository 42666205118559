import React from 'react'
import './birthday.css'
import Gift from './gift/Gift'

const Birthday = () => {
  return (
    <div className='bitrhday__page'>
      <Gift />
      <div className='bitrhday__page__video-container'>
        <video src='./video/present.mp4' autoPlay className='bitrhday__page__video' />
      </div>
    </div>
  )
}

export default Birthday
