import React, { useRef, useLayoutEffect, useEffect, useState, useMemo } from 'react'
import debounce from 'lodash.debounce'
import Tabs from './tabs/Tabs'
import Cards from './cards/Cards'
import { Slider, Button, Waves, TempLogo } from '../../components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import './nft.css'

function useLatest(cb) {
  const cbRef = useRef(cb)

  useLayoutEffect(() => {
    cbRef.current = cb
  }, [cb])
  return cbRef
}

function makeThrottleHook(throttleFn) {
  return function Throttle(cb, ms) {
    const latestCb = useLatest(cb)

    const throttledFn = useMemo(
      () =>
        throttleFn((...args) => {
          latestCb.current(...args)
        }, ms),
      [ms, latestCb]
    )

    useEffect(() => () => throttledFn.cancel(), [throttledFn])

    return throttledFn
  }
}
function throttle(func, ms) {
  let isThrottled = false
  function wrapper() {
    if (isThrottled) {
      return
    }

    func.apply(this, arguments)

    isThrottled = true

    setTimeout(function () {
      isThrottled = false
    }, ms)
  }
  return wrapper
}
const useThrottle = makeThrottleHook(throttle)
const useDebounce = makeThrottleHook(debounce)

const Nft = ({ active, setActive }) => {
  gsap.registerPlugin(ScrollToPlugin)
  gsap.registerPlugin(ScrollTrigger)
  const ref = useRef(null)
  const section1 = useRef()
  const section2 = useRef()
  const section3 = useRef()
  const slide1 = useRef()
  const slide2 = useRef()
  const slide3 = useRef()
  const [isMobile, setIsMobile] = useState(false)

  const goToSlide = useDebounce((direction) => {
    if (active >= 0 && active <= 2) {
      console.log('active on', active)
      if (direction === 'up') {
        if (active === 0) {
          gsap.to(ref.current, { duration: 1.8, ease: 'power4.inOut', scrollTo: { y: '#section2' } })

          setActive(1)
        } else if (active === 1) {
          gsap.to(ref.current, { duration: 1.8, ease: 'power4.inOut', scrollTo: { y: '#section3', offsetY: 25 } })
          setActive(2)
          return
        } else if (active === 2) {
          return
        }
      } else if (direction === 'down') {
        if (active === 0) {
          return
        }
        if (active === 1) {
          gsap.to(ref.current, { duration: 1.8, ease: 'power4.inOut', scrollTo: { y: '#section1' } })
          setActive(0)
        } else if (active === 2) {
          gsap.to(ref.current, { duration: 1.8, ease: 'power4.inOut', scrollTo: { y: '#section2' } })
          setActive(1)
        }
        return
      }
    }
    console.log('active off', active)
    return
  }, 300)
  useEffect(() => {
    const nft = ref.current

    let mm = gsap.matchMedia()

    mm.add('(max-width: 1025px)', () => {
      setIsMobile(true)

      let tl = gsap.timeline({
        defaults: {
          ease: 'power4.inOut',
        },
      })

      if (active === 0) {
        gsap.to('.video', {
          autoAlpha: 1,
        })
        tl.to('.tab', {
          autoAlpha: 1,
          duration: 1,
          pointerEvents: 'all',
        })

        tl.to('.tab-content-m-2', {
          autoAlpha: 0,
          duration: 1.6,
        })
        gsap.to('.tab-content-m-1', {
          autoAlpha: 1,
          duration: 1.6,
        })
      }
      if (active === 1) {
        gsap.to('.video', {
          opacity: 0.3,
        })
        gsap.to('.tab', {
          autoAlpha: 1,
          duration: 1,
          pointerEvents: 'all',
        })
        tl.to('.tab-content-m-1', {
          autoAlpha: 0,
          duration: 1.3,
        })
        tl.to('.tab-content-m-2', {
          autoAlpha: 1,
          duration: 1.6,
        })
      }
      if (active === 2) {
        gsap.to('.tab', {
          autoAlpha: 0,
          duration: 0.8,
          pointerEvents: 'none',
        })
        tl.to('.tab-content-m-2', {
          autoAlpha: 0,
          duration: 1.6,
        })
        gsap.to('.video', { opacity: 0 })
      }

      gsap.to('.social-widget', {
        autoAlpha: 1,
      })

      ScrollTrigger.normalizeScroll({
        type: 'touch',
        wheelSpeed: -1,
        tolerance: 10,
        onUp: () => goToSlide('up'),
        onDown: () => goToSlide('down'),
        preventDefault: true,
        lockAxis: true,
      })
    })

    return () => {
      mm.revert()
    }
  }, [active])

  useLayoutEffect(() => {
    const nftPage = ref.current

    let mm = gsap.matchMedia()
    mm.add('(min-width: 1025px)', () => {
      setIsMobile(false)
      let tl = gsap.timeline({
        defaults: { ease: 'power4.easeInOut' },
      })

      let currentSection = 0

      const goToSection = throttle((direction) => {
        if (direction === 'up') {
          if (currentSection === 0) {
            gsap.to('.second__container', { y: 0, duration: 1, ease: 'power4.easeInOut' })
            tl.to('.cards__block', { autoAlpha: 1, pointerEvents: 'all', duration: 1, ease: 'power4.easeInOut' })
            currentSection++
            return
          }
          if (currentSection === 1) {
            gsap.to(nftPage, { duration: 1, scrollTo: { y: '.second__container' } })
            gsap.to('.second__container', { duration: 1, y: '5.5vw' })
            tl.to('.video__container', { autoAlpha: 0, duration: 0.6 })
            tl.to('.tabs__container', { autoAlpha: 0, duration: 0.6 })
            gsap.to('.social-title__container', { autoAlpha: 0, duration: 0.6 })

            currentSection++
            return
          }
          if (currentSection === 2) {
            gsap.to(nftPage, { duration: 1, scrollTo: { y: '.third__container' } })
            currentSection++
            return
          }
          if (currentSection === 3) {
            return
          }
          return
        }
        if (direction === 'down') {
          if (currentSection === 0) {
            return
          }
          if (currentSection === 1) {
            tl.to('.cards__block', { autoAlpha: 0.45, duration: 1, pointerEvents: 'none' })
            gsap.to('.second__container', { y: '11vw', duration: 1 })
            currentSection--
            return
          }
          if (currentSection === 2) {
            gsap.to(nftPage, { duration: 0.8, scrollTo: { y: 0 } })
            gsap.to('.second__container', { y: 0, duration: 1 })
            tl.to('.video__container', { y: 0, autoAlpha: 1, duration: 0.5 })
            tl.to('.tabs__container', { y: 0, autoAlpha: 1, duration: 0.5 })
            gsap.to('.social-title__container', { autoAlpha: 1, duration: 0.6 })
            currentSection--
            return
          }
          if (currentSection === 3) {
            tl.to('.second__container', { duration: 1, y: '11vw' })
            gsap.to(nftPage, { duration: 0.8, scrollTo: { y: '.second__container' } })
            currentSection--
            return
          }
        }
      }, 1500)

      ScrollTrigger.normalizeScroll({
        type: 'wheel,pointer, touch, scroll',
        wheelSpeed: -1,
        tolerance: 10,
        onUp: () => goToSection('up'),
        onDown: () => goToSection('down'),
        preventDefault: true,
      })
    })

    return () => mm.revert()
  }, [])

  useEffect(() => {
    document.getElementById('video').play()
    gsap.set(ref.current, { scrollTo: { y: '#section1' } })
  }, [])

  const waveStyle = {
    position: 'absolute',
  }
  const tempLogoStyle = {
    position: 'absolute',
  }
  const btnStyle = {
    background: '#000',
    zIndex: 10,
  }

  return (
    <div className='nft__container' ref={ref}>
      <section className='first__container section__padding' ref={section1}>
        <Tabs active={active} set={setActive} />
      </section>
      <div className='video__container'>
        <video id='video' preload='auto' autoPlay loop muted playsInline className='video'>
          <source src='./video/bg.mp4' type='video/mp4' />
        </video>
      </div>
      {isMobile && <Slider className='errpr__container mob-section' id='section3' />}
      {!isMobile && (
        <section className='second__container section__padding' ref={section2}>
          <Cards />
        </section>
      )}
      {!isMobile && (
        <section className='third__container' ref={section3}>
          <div className='temp__desc-container'>
            <p className='temp__desc temp__text'>DOM WORLD&nbsp;is a&nbsp;phygital ecosystem the foundation of&nbsp;which is&nbsp;to&nbsp;give the creative community an&nbsp;opportunity to&nbsp;interact and discover new realms and domains in&nbsp;an&nbsp;environment that boosts creativity and freedom.</p>
            <p className='temp__desc temp__text'>Where Users, Brands and Creators will be&nbsp;able to&nbsp;unite for creativity, collaborations and partnerships.</p>
          </div>
          <Button title='mint nft' style={btnStyle} />
          <Waves style={waveStyle} />
          <TempLogo style={tempLogoStyle} />
        </section>
      )}
    </div>
  )
}

export default Nft
