import React, { useState } from 'react'
import './socials.css'

const socials = [
  { title: 'instagram', url: './images/inst.svg', href: 'https://www.instagram.com/domworld.io/' },
  { title: 'twitter', url: './images/twitter.svg', href: 'https://twitter.com/DOMWORLD_io' },
  { title: 'discord', url: './images/discord.svg', href: 'https://discord.gg/hSWQPRJNDu' },
  { title: 'linkedin', url: './images/linkedin.svg', href: 'https://www.linkedin.com/company/domworld/' },
]

const Socials = () => {
  const [open, setOpen] = useState(false)
  const [bounce, setBounce] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }
  const handleBounce = () => {
    setBounce(!bounce)
  }

  return (
    <div className={`social-widget ${open ? 'show' : ''} ${bounce && !open ? 'bounce' : ''}`}>
      <div className='social-title__container' onClick={handleToggle}>
        <p className={`social-title ${open ? 'invisible' : ''}`}>SOCIALS</p>
      </div>
      <div className='socials__container'>
        <button className='socials-btn' onClick={handleToggle} onMouseEnter={handleBounce} onMouseLeave={handleBounce}>
          {open ? <img src='./images/close.svg' alt='' /> : <img src='./images/open.svg' className='socials-btn_open' alt='' />}
        </button>
        <div className='socials__icons'>
          {socials.map((item, i) => (
            <a href={item.href} key={i} target='_blank' rel='noreferrer noopener'>
              <img src={item.url} alt={item.title} />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Socials
