import React from 'react'
import './title.css'
import { useDencrypt } from 'use-dencrypt-effect'

const Title = (props) => {
  const chars = '-./^*!}<~$0123456789QWERTYUIOPASDFGHJKLZXCVBNM'
  const [value, setValue] = useDencrypt(props.initialValue, { chars: chars })

  return (
    <div onMouseEnter={() => setValue(props.newValue)} onMouseLeave={() => setValue(props.initialValue)}>
      {value}
    </div>
  )
}

export default Title
