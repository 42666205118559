import React, { useState, useEffect } from 'react'
import { Header, Socials } from './components'
import { Nft, Temporary } from './pages'

import './App.css'

function App() {
  let temp = true

  const [active, setActive] = useState(0)
  const [title, setTitle] = useState('GOLDEN RESIDENT')
  useEffect(() => {
    if (!temp) {
      active === 2 ? setTitle('UTILITY') : setTitle('GOLDEN RESIDENT')
    } else {
      setTitle('')
    }

    return () => {}
  }, [active, title, temp])

  return (
    <div className='App'>
      <Header active={active} setActive={setActive} title={title} temp={temp} />

      {!temp && <Nft active={active} setActive={setActive} />}
      <Socials />
      {temp && <Temporary />}
    </div>
  )
}

export default App
