import * as React from 'react'
import { memo } from 'react'
import './waves.css'

const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={1280} height={849} fill='none' className='animation' {...props}>
    <path fill='url(#a)' d='M717 699.7c-18.7-19-47.2-30-78.2-30a110 110 0 0 0-71.7 24.6 80.4 80.4 0 0 0-29 61.9c0 28.2 10.1 52 29.5 68.6a103.8 103.8 0 0 0 54.3 23v-1.6a102.1 102.1 0 0 1-53.3-22.6 85.9 85.9 0 0 1-29-67.4c0-23.4 10.1-45 28.5-60.7a108.5 108.5 0 0 1 70.7-24.3c30.6 0 58.7 10.8 77.1 29.5a76.5 76.5 0 0 1 22.2 56.4c-1.4 65-41.7 85.3-81.1 89.4v1.6c22-2.3 40-9 53.4-20.2 18.7-15.5 28.5-39.3 29.2-70.8a78 78 0 0 0-22.6-57.4Z' opacity={0.5} />
    <path fill='url(#b)' d='M728.7 686.7c-21.1-21.5-54-33.8-90.2-33.8-31.3 0-60 9.4-81 26.5a86.8 86.8 0 0 0-33 68.2 94 94 0 0 0 33.6 75 120.7 120.7 0 0 0 63.8 25.3v-1.5a119.1 119.1 0 0 1-62.8-25 92.5 92.5 0 0 1-33-73.8 85.4 85.4 0 0 1 32.4-67 126.4 126.4 0 0 1 80-26.1c35.8 0 68.3 12.1 89 33.3a82.6 82.6 0 0 1 24.1 60.6c-.6 33.7-11.6 59.3-32.6 76.1-15.6 12.5-36.5 20-62 22.2v1.5c25.9-2.3 47-9.8 63-22.5 21.3-17.1 32.5-43 33.1-77.2a84 84 0 0 0-24.4-61.8Z' opacity={0.5} />
    <path fill='url(#c)' d='M740.3 673.7c-23.4-23.8-60.6-37.5-102-37.5-35.1 0-67.3 10-90.4 28.3A93.4 93.4 0 0 0 511 739c0 33.6 13 61.7 37.5 81.4a138 138 0 0 0 73.3 27.6v-1.5a136.4 136.4 0 0 1-72.4-27.2 99.3 99.3 0 0 1-36.9-80.3 92 92 0 0 1 36.2-73.3c23-18 54.7-28 89.5-28 41 0 77.8 13.5 101 37a88.7 88.7 0 0 1 25.8 65.1c-.6 36.3-13 64-36.5 82.4-18.1 14.1-42.2 22.3-71.7 24.6v1.5c29.9-2.3 54.2-10.6 72.6-24.9 24-18.7 36.5-46.8 37.1-83.6a90.2 90.2 0 0 0-26.3-66.1Z' opacity={0.5} />
    <path fill='url(#d)' d='M752 660.6c-25.8-26.2-67.3-41.2-114-41.2-39 0-74.3 10.8-99.8 30.2a98.7 98.7 0 0 0-40.6 80.8c0 36.2 14.4 66.6 41.5 87.9a155.5 155.5 0 0 0 82.8 29.8v-1.5a154 154 0 0 1-81.9-29.5c-26.7-21-40.9-51-40.9-86.7 0-32 13.9-59.5 40-79.6 25.2-19.3 60.3-29.9 98.9-29.9 46.3 0 87.4 14.9 113 40.7a95 95 0 0 1 27.7 69.6c-.6 39-14.3 68.8-40.6 88.8-20.2 15.3-48 24.5-81.1 26.8v1.5c33.4-2.3 61.6-11.6 82-27.1 26.7-20.3 40.5-50.6 41.2-90 .4-27-9.4-51.5-28.2-70.6Z' opacity={0.5} />
    <path
      fill='url(#e)'
      d='M763.6 647.5c-28-28.5-74-44.8-125.9-44.8-42.7 0-81.4 11.4-109.1 32a105.3 105.3 0 0 0-44.4 87c0 39 15.7 71.6 45.4 94.4 23.8 18.2 56 29.3 92.3 32.1v-1.5C586 844 554 833 530.5 815a113 113 0 0 1-44.9-93.1 104 104 0 0 1 43.9-85.9c27.4-20.5 65.8-31.7 108.2-31.7 51.5 0 97 16.1 124.9 44.3 19.7 20 30 45.7 29.6 74-.6 41.6-15.6 73.7-44.5 95.2-22.6 16.9-53.8 26.9-90.8 29.2v1.5c15.5-1 29.9-3.3 43.1-6.8 18.8-5 35.1-12.7 48.6-22.7 29.3-21.9 44.4-54.3 45-96.3.5-28.8-10-54.8-30-75.1Z'
      opacity={0.5}
    />
    <path
      fill='url(#f)'
      d='M775.2 634.3C745 603.6 694.6 586 637.4 586c-46.5 0-88.6 12-118.4 34a112 112 0 0 0-48.2 93.2c0 41.6 17 76.5 49.3 100.8 26.1 19.7 61.8 31.6 101.8 34.3v-1.4c-39.6-2.7-75-14.6-101-34a120 120 0 0 1-48.7-99.7c0-37.4 16.5-69.3 47.6-92 29.7-21.8 71.4-33.7 117.6-33.7 56.9 0 106.7 17.5 136.8 48 21 21.2 31.9 48.3 31.5 78.5-.6 44.2-16.9 78.4-48.4 101.6-25 18.3-59.5 29.1-100.3 31.4v1.5c41.1-2.4 75.8-13.2 101.1-31.8 32-23.4 48.5-58 49-102.7a109 109 0 0 0-31.9-79.6Z'
      opacity={0.5}
    />
    <path
      fill='url(#g)'
      d='M786.9 621.2c-32.7-33-87.3-52-149.8-52-50.3 0-95.7 12.7-127.8 35.8-34 24.4-52 58.8-52 99.5 0 44.3 18.4 81.4 53.3 107.2 28.5 21.1 67.6 33.9 111.3 36.6v-1.4c-43.4-2.8-82.2-15.4-110.5-36.3a127 127 0 0 1-52.7-106c0-40.3 17.8-74.3 51.4-98.5 32-22.9 77-35.5 127-35.5 62.2 0 116.4 18.8 148.8 51.6a112.4 112.4 0 0 1 33.4 83c-.6 47-18.3 83.3-52.5 108-27.4 19.9-65.2 31.4-109.9 33.8v1.4c45-2.3 83-14 110.8-34 16.6-12 29.5-27.1 38.4-44.8a146 146 0 0 0 14.6-64.3c.3-32.3-11.4-61.4-33.8-84.1Z'
      opacity={0.5}
    />
    <path
      fill='url(#h)'
      d='M798.5 608c-35-35.3-93.8-55.5-161.6-55.5-54.2 0-102.9 13.3-137.2 37.6a125.4 125.4 0 0 0-55.9 105.8c0 47 19.8 86.3 57.3 113.6 30.9 22.6 73.3 36.1 120.8 38.8V847c-47.2-2.7-89.3-16.1-120-38.5-37-27.1-56.7-66-56.7-112.5a124 124 0 0 1 55.3-104.7c34-24 82.5-37.3 136.4-37.3 67.4 0 126 20 160.6 55 23.8 24 35.6 53.6 35.3 87.8-.3 25.1-5.5 47.8-15.5 67.3a130.3 130.3 0 0 1-40.9 47c-30 21.3-71 33.7-119.5 36v1.4c48.8-2.3 90.2-14.8 120.3-36.3a131.7 131.7 0 0 0 41.4-47.4 152 152 0 0 0 15.6-68 120 120 0 0 0-35.7-88.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#i)'
      d='M810.1 594.8c-37.2-37.6-100.4-59-173.5-59-29 0-56.3 3.3-81.1 10-25 6.7-47.1 16.6-65.5 29.4a132 132 0 0 0-59.6 112.1c0 49.6 21.2 91.1 61.2 120 33.3 24 79 38.3 130.3 41V847c-51-2.7-96.4-17-129.5-40.8a141.3 141.3 0 0 1-60.6-118.9c0-45.7 20.4-84.1 59-111a207 207 0 0 1 65-29.2c24.8-6.6 52-10 80.8-10 72.7 0 135.6 21.4 172.5 58.6a125 125 0 0 1 37.2 92.3c-.3 26.5-5.8 50.4-16.5 71a138.7 138.7 0 0 1-43.8 49.7c-32.4 22.9-76.7 36-129 38.4v1.3c52.6-2.3 97.2-15.5 129.8-38.6 19.1-13.5 34-30.4 44.2-50.1a158 158 0 0 0 16.7-71.7c.3-36.4-12.3-67.7-37.6-93.2Z'
      opacity={0.5}
    />
    <path
      fill='url(#j)'
      d='M821.8 581.6a197.6 197.6 0 0 0-79.4-46.8 337.3 337.3 0 0 0-106-15.8c-31 0-60 3.5-86.4 10.5-26.7 7-50 17.4-69.6 30.8-41.5 28.5-63.4 69.4-63.4 118.4 0 52.3 22.5 96 65.1 126.4a223 223 0 0 0 70.8 32.6c21.5 6 44.7 9.5 69 10.7V847c-54.7-2.7-103.6-17.7-139-43a148.4 148.4 0 0 1-64.6-125.3c0-48.5 21.7-89 62.9-117.3a221 221 0 0 1 69.1-30.6 338 338 0 0 1 86-10.5c78 0 145.3 22.7 184.5 62.2 26.2 26.5 39.4 59 39 96.9-.2 27.8-6.1 53-17.5 74.7a147.3 147.3 0 0 1-46.7 52.3c-34.8 24.4-82.5 38.4-138.6 40.7v1.4c56.4-2.4 104.3-16.4 139.3-41 20.5-14.2 36.3-32 47.2-52.8a164 164 0 0 0 17.7-75.3c.3-38.2-13-71.1-39.4-97.8Z'
      opacity={0.5}
    />
    <path
      fill='url(#k)'
      d='M833.4 568.3A208.7 208.7 0 0 0 749 519a362.3 362.3 0 0 0-113-16.7c-32.7 0-63.5 3.7-91.5 11a236.5 236.5 0 0 0-73.8 32.2c-44 29.9-67.2 73-67.2 124.7 0 55 23.9 100.9 69.1 132.8 21.1 15 46.4 26.5 75 34.3a341 341 0 0 0 74.3 11.2V847c-25.9-1-50.9-4.9-73.9-11a236.2 236.2 0 0 1-74.6-34 155.5 155.5 0 0 1-68.6-132c0-51.2 23-94 66.7-123.6 20.6-14 45.2-24.7 73.4-32a364.2 364.2 0 0 1 91.1-11c41 0 79 5.7 112.6 16.7a207.3 207.3 0 0 1 83.8 49 137.7 137.7 0 0 1 41 101.6 169 169 0 0 1-18.6 78.3 155.8 155.8 0 0 1-49.7 55c-37.2 26-88.2 40.7-148.2 43v1.4c60.3-2.4 111.5-17.2 149-43.2a157 157 0 0 0 50-55.6c12.2-23 18.5-49.5 18.8-79a139 139 0 0 0-41.3-102.4Z'
      opacity={0.5}
    />
    <path
      fill='url(#l)'
      d='M845 555c-22.4-22.4-52.5-40-89.4-52a387.5 387.5 0 0 0-119.8-17.5c-70.5 0-130.9 15.5-174.7 45-46.5 31.2-71 76.5-71 131a164 164 0 0 0 73 139.2c22.3 15.7 49 27.7 79.4 36a364 364 0 0 0 79.4 11.7V847c-28-1.2-54.5-5.1-79.1-11.8a250.7 250.7 0 0 1-79-35.6 162.7 162.7 0 0 1-72.4-138.2c0-54 24.3-99 70.4-130 43.6-29.2 103.8-44.7 174-44.7 43.5 0 83.7 5.9 119.4 17.5 36.7 12 66.6 29.3 88.8 51.7a144 144 0 0 1 43 106.2c-.3 30.5-6.9 58.1-19.7 82a164.2 164.2 0 0 1-52.5 57.8c-39.7 27.4-94 42.9-157.8 45.2v1.4c64-2.4 118.6-18 158.5-45.5 23-15.8 40.8-35.4 53-58.2 12.9-24.1 19.6-52 19.8-82.7.3-41.8-14.2-77.8-43.2-107Z'
      opacity={0.5}
    />
    <path
      fill='url(#m)'
      d='M856.6 541.8c-23.5-23.7-55.3-42-94.4-54.7a412.8 412.8 0 0 0-126.7-18.4c-74.4 0-138 16.2-184 46.9-49 32.6-74.9 80-75 137.3 0 60.3 26.7 110.7 77 145.7 23.6 16.3 51.7 28.9 83.7 37.5 26.4 7 54.8 11.1 84.7 12.3V847a385.7 385.7 0 0 1-84.3-12.3c-31.9-8.5-59.9-21-83.3-37.3-50-34.8-76.4-84.8-76.4-144.6 0-56.8 25.7-103.9 74.3-136.2C498 486.2 561.4 470 635.5 470c46.1 0 88.6 6.2 126.3 18.3 38.9 12.6 70.5 31 93.9 54.5 30 30.1 45 67.4 44.7 110.7-.2 31.8-7.2 60.6-20.7 85.7a172.8 172.8 0 0 1-55.5 60.5c-42.1 28.8-99.7 45.1-167.3 47.4v1.4c67.9-2.4 125.7-18.8 168-47.8a174 174 0 0 0 56-61 182.7 182.7 0 0 0 20.8-86.2c.3-43.7-14.9-81.2-45.1-111.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#n)'
      d='M868.2 528.6c-24.7-24.9-58.1-44.2-99.4-57.4A438.2 438.2 0 0 0 635.2 452c-78.2 0-145 16.8-193.4 48.7-51.4 34-78.6 83.6-78.6 143.6 0 63 28 115.5 80.9 152 24.7 17.1 54.3 30.3 88 39.2 27.9 7.4 58 11.7 89.8 12.9v-1.3a409 409 0 0 1-89.6-12.9 280 280 0 0 1-87.5-38.9c-52.6-36.3-80.4-88.5-80.4-151 0-59.5 27-108.8 78.1-142.5 48.1-31.8 114.7-48.6 192.7-48.6 48.8 0 93.6 6.5 133.2 19.2 41 13.2 74.3 32.4 98.9 57 31.2 31.5 47 70.3 46.7 115.4-.3 33.2-7.6 63.3-21.8 89.4a181.3 181.3 0 0 1-58.4 63.2c-44.6 30.3-105.5 47.4-176.8 49.8v1.2c71.6-2.3 132.7-19.5 177.5-50a182.6 182.6 0 0 0 58.8-63.6 188.8 188.8 0 0 0 22-90 158 158 0 0 0-47-116.2Z'
      opacity={0.5}
    />
    <path
      fill='url(#o)'
      d='M879.8 515.3c-25.9-26-61-46.2-104.4-60-41.7-13.3-89-20-140.5-20-82 0-152.2 17.4-202.7 50.5-54 35.3-82.5 87.2-82.5 149.8 0 65.7 29.4 120.5 85 158.6 25.8 17.7 56.8 31.4 92.1 40.7a433.5 433.5 0 0 0 95.1 13.5v-1.3a432 432 0 0 1-94.8-13.4c-35-9.2-66-22.9-91.8-40.6C380.1 755.3 351 701 351 635.6c0-31.8 7.3-61 21.8-86.4a183 183 0 0 1 60-62.3c50.4-33 120.3-50.4 202.1-50.4 51.4 0 98.5 6.7 140.2 20 43.1 13.7 78 33.8 103.8 59.7 32.5 32.7 48.8 73 48.6 120a194 194 0 0 1-22.8 93 189.9 189.9 0 0 1-61.3 66c-47 31.8-111.2 49.7-186.4 52v1.3c75.4-2.4 139.9-20.3 187.1-52.3 26.7-18 47.5-40.4 61.7-66.4 15-27.4 22.7-58.9 23-93.6.2-47.3-16.2-88-49-120.9Z'
      opacity={0.5}
    />
    <path
      fill='url(#p)'
      d='M891.4 502c-27-27.1-63.8-48.2-109.4-62.6a489.2 489.2 0 0 0-147.3-21c-86 0-159.4 18.2-212.2 52.5a191.6 191.6 0 0 0-63.2 65.3 180.8 180.8 0 0 0-23 90.8c0 68.4 30.7 125.4 88.8 165a310.5 310.5 0 0 0 96.5 42.4 456.9 456.9 0 0 0 100.3 14v-1.2a456 456 0 0 1-100-14 309 309 0 0 1-96-42.2c-57.9-39.4-88.4-96-88.4-164 0-33.3 7.7-63.7 22.9-90.2 14.4-25.3 35.5-47.1 62.8-64.8 52.6-34.2 125.7-52.3 211.5-52.3a488 488 0 0 1 147 20.8C827 455 863.7 476 890.6 503A169.5 169.5 0 0 1 941 627.6c-.2 35.8-8.2 68.3-23.8 96.7a198.5 198.5 0 0 1-64.2 68.6c-49.5 33.3-117 52-196 54.3v1.3c79.2-2.4 147-21.1 196.7-54.6 28-18.8 49.7-42 64.6-69a201.6 201.6 0 0 0 24-97.3c.2-49.1-16.9-91.4-50.9-125.5Z'
      opacity={0.5}
    />
    <path
      fill='url(#q)'
      d='M903 488.8c-28.2-28.4-66.7-50.3-114.3-65.4a514.7 514.7 0 0 0-154.3-21.7c-90 0-166.5 18.8-221.5 54.3a199 199 0 0 0-66 67.8c-16 27.8-24.1 59.7-24.1 94.6 0 71 32 130.3 92.8 171.4a325 325 0 0 0 100.8 44 480.3 480.3 0 0 0 105.5 14.6v-1.2c-37.2-1.2-72.6-6.1-105.2-14.6a323.6 323.6 0 0 1-100.4-43.8C355.9 747.9 324 689 324 618.4c0-34.7 8-66.4 24-94 15-26.3 37-49 65.6-67.4 54.8-35.4 131.1-54 220.8-54 56.5 0 108.3 7.2 153.9 21.6 47.5 15 85.8 36.8 113.9 65 35 35.2 52.6 78.7 52.4 129.3-.2 37.2-8.6 71-25 100.4a207 207 0 0 1-67 71.3c-52 34.8-122.8 54.3-205.6 56.6v1.2c83-2.3 154-21.8 206.2-56.8 29.2-19.5 52-43.7 67.5-71.7a207.9 207.9 0 0 0 25-101A177 177 0 0 0 903 488.8Z'
      opacity={0.5}
    />
    <path
      fill='url(#r)'
      d='M914.6 475.5c-29.3-29.5-69.5-52.4-119.3-68-47.8-15-102-22.5-161.2-22.5-93.8 0-173.6 19.4-230.9 56.1a206.3 206.3 0 0 0-68.8 70.3c-16.6 29-25 62-25 98.4 0 73.7 33.4 135.2 96.7 177.8a339.6 339.6 0 0 0 105 45.6c34.4 9 71.6 14 110.8 15.2v-1.2A502.4 502.4 0 0 1 511.4 832a338.3 338.3 0 0 1-104.6-45.5c-63-42.4-96.3-103.5-96.3-176.8 0-36.2 8.4-69 25-97.8a205.1 205.1 0 0 1 68.4-69.9c57-36.6 136.6-56 230.2-56 59 0 113.2 7.6 160.8 22.6 49.6 15.5 89.6 38.3 118.9 67.7a182.2 182.2 0 0 1 54.3 134 213 213 0 0 1-26 104 215.6 215.6 0 0 1-70 74c-54.4 36.3-128.5 56.5-215.2 58.8v1.2c87-2.3 161.2-22.6 215.8-59a216.7 216.7 0 0 0 70.4-74.5c17.2-30.7 26-65.9 26.2-104.6.2-52.8-18.2-98.1-54.7-134.8Z'
      opacity={0.5}
    />
    <path
      fill='url(#s)'
      d='M926.3 462.3c-30.6-30.7-72.5-54.5-124.4-70.7a566 566 0 0 0-168-23.4c-97.8 0-180.9 20-240.3 58a213.7 213.7 0 0 0-71.6 72.9c-17.3 30-26.1 64.3-26.1 102.1 0 76.4 34.8 140 100.7 184.2a354.4 354.4 0 0 0 109.3 47.3c36 9.2 75 14.5 116 15.7v-1.2c-41-1.2-79.8-6.4-115.7-15.7a352.9 352.9 0 0 1-109-47c-65.5-44-100.1-107.4-100.1-183.3 0-37.6 8.7-71.8 25.9-101.6a212.5 212.5 0 0 1 71.2-72.4c59.3-37.8 142.1-57.8 239.6-57.8a565 565 0 0 1 167.7 23.3c51.8 16.2 93.5 39.8 123.9 70.4a188.7 188.7 0 0 1 56.2 138.6c-.2 39.8-9.3 76-27 107.7a224.2 224.2 0 0 1-73 76.7 347.5 347.5 0 0 1-108.4 46A539 539 0 0 1 657 847.4v1c41.6-1.1 80.7-6 116.6-15 41.8-10.4 78.4-26 108.8-46.2 31.7-21 56.4-47 73.3-77.1 17.9-31.9 27-68.3 27.2-108.3a189.8 189.8 0 0 0-56.5-139.4Z'
      opacity={0.5}
    />
    <path
      fill='url(#t)'
      d='M937.9 449c-31.8-31.9-75.3-56.5-129.4-73.3a591.9 591.9 0 0 0-175-24.2c-101.6 0-187.9 20.7-249.6 59.8a221.2 221.2 0 0 0-74.4 75.4c-18 31-27 66.6-27 105.9 0 79 36.1 145 104.6 190.6 31.9 21.3 70.1 37.8 113.6 48.9a550 550 0 0 0 121.2 16.3v-1.2A548.9 548.9 0 0 1 501 831a367.8 367.8 0 0 1-113.2-48.8c-68-45.4-104.1-111-104-189.6 0-39 9-74.5 26.8-105.3a220 220 0 0 1 74-75c61.6-39 147.7-59.7 249-59.7 64.3 0 123 8.2 174.6 24.2 54 16.7 97.3 41.3 129 73A195 195 0 0 1 995 593c-.2 41.2-9.6 78.7-28 111.4-17.5 31-43 57.7-75.9 79.5a362.4 362.4 0 0 1-112.7 47.7 562.5 562.5 0 0 1-121.6 15.6v1.2c43.5-1 84.4-6.3 121.9-15.7 43.4-10.8 81.4-27 113-47.8 33-21.9 58.7-48.8 76.3-80a227 227 0 0 0 28.2-111.8c.2-56.5-19.5-105-58.4-144.1Z'
      opacity={0.5}
    />
    <path
      fill='url(#u)'
      d='M949.5 435.7c-33-33-78.1-58.6-134.4-76a617.7 617.7 0 0 0-181.8-25c-105.5 0-195 21.4-259 61.8a228.5 228.5 0 0 0-77.2 77.8 215.3 215.3 0 0 0-28 109.7c0 81.7 37.5 149.8 108.5 197 33.1 22 72.8 39 117.9 50.6a574 574 0 0 0 126.4 16.8v-1.2c-44.7-1.2-87-6.8-126.2-16.8a382.5 382.5 0 0 1-117.5-50.3c-70.7-47-108-114.8-108-196.1 0-40.5 9.4-77.2 28-109.1a227.4 227.4 0 0 1 76.7-77.5C438.7 357.2 528 336 633.3 336c66.8 0 127.9 8.4 181.5 25 56 17.3 101 42.7 133.9 75.6 40 40.2 60.2 90 60 148-.2 42.4-10 81.1-29.2 115a241.4 241.4 0 0 1-78.7 82.1 377.4 377.4 0 0 1-117 49.4c-39 9.7-81.7 15.2-126.8 16.3v1c45.2-1.1 88-6.4 127-16.2 45-11.2 84.6-27.9 117.4-49.5a242.5 242.5 0 0 0 79.1-82.6c19.3-34 29.2-72.9 29.3-115.6a202.6 202.6 0 0 0-60.3-148.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#v)'
      d='M961 422.4c-34-34.2-80.9-60.6-139.3-78.6A643.6 643.6 0 0 0 633 318c-109.4 0-202.2 22-268.3 63.6a235.9 235.9 0 0 0-80 80.3 222.2 222.2 0 0 0-29.1 113.5c0 84.3 38.9 154.7 112.5 203.4A398.2 398.2 0 0 0 490.2 831c40.8 10.4 85 16.2 131.7 17.4v-1.2c-46.5-1.2-90.7-7-131.4-17.3a397.1 397.1 0 0 1-121.8-52c-73.3-48.5-112-118.5-112-202.5a221 221 0 0 1 29-112.9c18.2-31.3 45-58.2 79.6-80 66-41.4 158.5-63.4 267.7-63.4 69.4 0 132.8 8.7 188.4 25.8 58.2 18 105 44.3 138.9 78.3a207.8 207.8 0 0 1 61.9 152.6A238.7 238.7 0 0 1 992 694.5a250 250 0 0 1-81.6 84.9 392.4 392.4 0 0 1-121.4 51 608.7 608.7 0 0 1-132 16.9v1.1c47.1-1 91.5-6.7 132.3-16.9A393.5 393.5 0 0 0 911 780.3a251 251 0 0 0 82-85.3c20-35 30.2-75.2 30.3-119.2a209 209 0 0 0-62.2-153.4Z'
      opacity={0.5}
    />
    <path
      fill='url(#w)'
      d='M972.7 409.1c-35.3-35.3-83.8-62.6-144.4-81.2-57.7-17.7-123.5-26.7-195.6-26.7-113.3 0-209.3 22.7-277.7 65.5a243.3 243.3 0 0 0-82.8 82.9 229.1 229.1 0 0 0-30.1 117.2c0 87 40.3 159.6 116.5 209.9A413 413 0 0 0 485 830.4a620.8 620.8 0 0 0 137 18v-1.1a619.2 619.2 0 0 1-136.7-18 412 412 0 0 1-126.1-53.6c-75.9-50-116-122.3-116-209a228 228 0 0 1 30-116.6c18.9-32.3 46.6-60 82.4-82.5 68.2-42.7 164-65.2 277.1-65.2 72 0 137.7 9 195.3 26.6 60.3 18.5 108.8 45.7 143.9 81 42.5 42.6 64 95.5 63.8 157.2a245.3 245.3 0 0 1-31.2 122.3 258.5 258.5 0 0 1-84.6 87.6A407.3 407.3 0 0 1 794.3 830a630.7 630.7 0 0 1-137.4 17.4v1.1c49-1.1 95.2-7 137.6-17.5 48.4-12 90.7-29.7 126-52.8a259.7 259.7 0 0 0 85-88 246.2 246.2 0 0 0 31.3-123c.2-62-21.4-115-64.1-158Z'
      opacity={0.5}
    />
    <path
      fill='url(#x)'
      d='M984.3 395.8c-36.4-36.5-86.7-64.7-149.4-83.8-59.7-18.3-127.8-27.5-202.5-27.5-117.2 0-216.4 23.3-287 67.3-37.2 23.2-66 52-85.6 85.4a236 236 0 0 0-31.1 121c0 89.7 41.6 164.5 120.4 216.3a427.8 427.8 0 0 0 130.7 55.4c44 11 91.8 17.3 142.1 18.5v-1.1c-50.3-1.2-98-7.4-141.8-18.5a426.6 426.6 0 0 1-130.4-55.3c-78.5-51.5-120-126-120-215.3a235 235 0 0 1 31-120.4c19.6-33.4 48.2-62 85.2-85 70.5-44 169.5-67.2 286.5-67.2 74.6 0 142.6 9.2 202.2 27.4 62.5 19.1 112.6 47.3 148.9 83.6 43.8 44 66 98.4 65.8 162-.2 46.4-11 88.8-32.3 126a267.2 267.2 0 0 1-87.5 90.3 422.3 422.3 0 0 1-130 54.4 654 654 0 0 1-142.5 18v1c50.8-1 98.8-7 142.8-18 50-12.3 93.8-30.7 130.3-54.5 38-24.9 67.6-55.4 87.8-90.7a252.6 252.6 0 0 0 32.4-126.6c.2-63.8-22-118.5-66-162.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#y)'
      d='M995.9 382.6c-37.6-37.7-89.5-66.8-154.4-86.6-61.6-18.7-132-28.3-209.3-28.3-121.1 0-223.7 24-296.5 69.2a258 258 0 0 0-88.3 88 243 243 0 0 0-32.2 124.7c0 92.3 43 169.3 124.4 222.7 38 24.8 83.3 44 135 57a667.7 667.7 0 0 0 147.3 19v-1a666 666 0 0 1-147-19 441.3 441.3 0 0 1-134.7-57c-81-53-124-129.7-124-221.7 0-46.2 10.9-88 32.1-124.2a257 257 0 0 1 88-87.6c72.7-45.1 175-69 295.9-69 77.1 0 147.5 9.5 209 28.3 64.7 19.7 116.5 48.7 154 86.2a227 227 0 0 1 67.6 166.6 258 258 0 0 1-33.4 129.7 275.9 275.9 0 0 1-90.3 93 437.4 437.4 0 0 1-134.3 56.1A677 677 0 0 1 657 847.3v1a678.4 678.4 0 0 0 148-18.5 438.3 438.3 0 0 0 134.6-56.3 277 277 0 0 0 90.8-93.4c22-38.4 33.3-82.2 33.5-130.2.1-65.7-22.8-122-68-167.3Z'
      opacity={0.5}
    />
    <path
      fill='url(#z)'
      d='M1007.5 369.3C968.7 330.4 915 300.5 848 280c-63.5-19.2-136.3-29-216-29-125 0-230.8 24.6-305.8 71a265.4 265.4 0 0 0-91.2 90.5 249.9 249.9 0 0 0-33.2 128.4c0 95 44.4 174.3 128.4 229.2 39.1 25.5 86 45.3 139.2 58.6A691 691 0 0 0 622 848.3v-1c-54-1.2-105.2-7.8-152.3-19.6a456 456 0 0 1-139-58.5C247 714.5 202.9 635.6 202.9 540.9c0-47.6 11.1-90.6 33-128a264.4 264.4 0 0 1 90.8-90c75-46.4 180.5-70.9 305.3-70.9 79.7 0 152.4 9.8 216 29.1 66.8 20.3 120.2 50.2 158.8 88.9a233.4 233.4 0 0 1 69.6 171.3c-.1 49.1-11.7 94-34.4 133.3a284.6 284.6 0 0 1-93.3 95.7A452.4 452.4 0 0 1 810 828.1a700 700 0 0 1-153 19.2v1a701.2 701.2 0 0 0 153.3-19.1 453.2 453.2 0 0 0 138.9-58c40.5-26.3 72-58.7 93.6-96a265.6 265.6 0 0 0 34.6-134 234.4 234.4 0 0 0-70-172Z'
      opacity={0.5}
    />
    <path
      fill='url(#A)'
      d='M1019 356c-39.8-40-95.1-70.9-164.2-91.8-65.7-19.9-140.8-30-223.2-30-129 0-237.9 25.2-315.2 73a272.8 272.8 0 0 0-93.9 92.9 256.8 256.8 0 0 0-34.2 132.2c0 97.8 45.7 179.2 132.3 235.6A471.9 471.9 0 0 0 464 828.2a715 715 0 0 0 157.8 20.1v-1c-55.9-1.2-108.8-8-157.5-20.1-54.8-13.7-103-34-143.3-60.2-86.2-56.2-131.8-137.3-131.8-234.7 0-49 11.5-93.3 34-131.7A271.9 271.9 0 0 1 317 308c77-47.6 185.9-72.7 314.6-72.7 82.3 0 157.3 10 222.8 29.9 69 20.9 124.1 51.6 164 91.5a239.8 239.8 0 0 1 71.4 176 271 271 0 0 1-35.4 137 293.2 293.2 0 0 1-96.2 98.4 467 467 0 0 1-143 59.5c-48.7 12-102 18.6-158.3 19.7v1a724.2 724.2 0 0 0 158.6-19.7A468.2 468.2 0 0 0 958.7 769a294.3 294.3 0 0 0 96.6-98.8 272.1 272.1 0 0 0 35.6-137.6c.1-69.3-24-128.8-71.8-176.6Z'
      opacity={0.5}
    />
    <path
      fill='url(#B)'
      d='M1030.7 342.7c-41.1-41.2-98-73-169.3-94.4-67.7-20.4-145-30.8-230-30.8-132.9 0-245.1 25.8-324.6 74.7-42 25.9-74.6 58-96.7 95.5a263.7 263.7 0 0 0-35.2 136c0 100.4 47 184 136.2 242 41.5 27 91.2 47.8 147.8 62a738.3 738.3 0 0 0 163 20.6v-1a737 737 0 0 1-162.8-20.7c-56.4-14-106-34.8-147.5-61.8-88.8-57.7-135.7-141-135.7-241 0-50.6 11.8-96.2 35-135.6 22-37.3 54.5-69.3 96.4-95.1 79.4-48.8 191.4-74.6 324-74.6 85 0 162.3 10.3 229.8 30.7 71 21.5 128 53.2 168.9 94.2a246.2 246.2 0 0 1 73.4 180.6 277.6 277.6 0 0 1-36.5 140.7 301.8 301.8 0 0 1-99.1 101.1A482.2 482.2 0 0 1 820.6 827 746.3 746.3 0 0 1 657 847.3v1A747 747 0 0 0 820.8 828a483.1 483.1 0 0 0 147.5-61.3c43-27.9 76.5-62 99.4-101.5a278.7 278.7 0 0 0 36.7-141.2 247.3 247.3 0 0 0-73.7-181.3Z'
      opacity={0.5}
    />
    <path
      fill='url(#C)'
      d='M1042.3 329.4c-42.3-42.3-101-75-174.3-97-69.6-21-149.3-31.6-237-31.6-136.7 0-252.1 26.4-333.9 76.5a287.7 287.7 0 0 0-99.5 98 270.6 270.6 0 0 0-36.2 139.8c0 103 48.5 189 140.2 248.4 42.7 27.7 93.9 49 152 63.6 52.1 13 108.7 20 168.3 21.2v-1c-59.6-1.2-116-8.3-168-21.2a500.3 500.3 0 0 1-151.8-63.4C210.7 703.4 162.4 617.8 162.4 515c0-51.9 12.1-98.7 36-139.2 22.8-38.4 56.1-71.3 99.3-97.7 81.5-50 196.8-76.4 333.4-76.4 87.5 0 167 10.6 236.6 31.5 73.2 22 131.7 54.6 173.9 96.8 50 50.2 75.4 112.5 75.3 185.3a284 284 0 0 1-37.6 144.3 310.4 310.4 0 0 1-102 104 497.2 497.2 0 0 1-151.5 62.7 769.4 769.4 0 0 1-168.8 21v1a770 770 0 0 0 169-21 498 498 0 0 0 151.8-63 311.4 311.4 0 0 0 102.4-104.2 285 285 0 0 0 37.7-144.8c.1-73-25.3-135.6-75.6-186Z'
      opacity={0.5}
    />
    <path
      fill='url(#D)'
      d='M1053.9 316.1c-43.4-43.5-103.8-77-179.3-99.7C803 194.9 721 184 630.8 184c-140.6 0-259.4 27.1-343.3 78.4A295.1 295.1 0 0 0 185.2 363 277.5 277.5 0 0 0 148 506.5c0 105.7 49.8 193.8 144 254.8 44 28.4 96.6 50.4 156.4 65.2A785.2 785.2 0 0 0 622 848.3v-1a784.5 784.5 0 0 1-173.2-21.8 514.9 514.9 0 0 1-156.1-65c-94-60.8-143.7-148.6-143.7-254 0-53.3 12.5-101.5 37.1-143 23.4-39.4 57.7-73.1 102-100.2C371.8 212 490.3 185 630.8 185c90 0 172 10.9 243.5 32.3 75.4 22.7 135.6 56.1 178.9 99.5 51.3 51.4 77.3 115.3 77.2 190 0 54.4-13 104.2-38.6 148a319.2 319.2 0 0 1-105 106.6 512.2 512.2 0 0 1-155.7 64.5A792.3 792.3 0 0 1 657 847.4v1a793 793 0 0 0 174.3-21.6 513 513 0 0 0 156.1-64.6 320 320 0 0 0 105.3-107 291.6 291.6 0 0 0 38.7-148.4c.1-75-26-139-77.5-190.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#E)'
      d='M1065.5 302.8c-44.6-44.6-106.6-79-184.3-102.3-73.6-22-158-33.2-250.7-33.2-144.5 0-266.5 27.7-352.6 80.3a302.7 302.7 0 0 0-105.1 103A284.4 284.4 0 0 0 134.5 498c0 108.4 51.2 198.7 148 261.2A530.7 530.7 0 0 0 443.3 826 809.1 809.1 0 0 0 622 848.3v-1A808 808 0 0 1 443.4 825a529.8 529.8 0 0 1-160.3-66.7C186.5 696 135.5 606 135.5 498c0-54.8 12.8-104.2 38-146.8 24-40.4 59.3-75 104.8-102.7 86-52.5 207.8-80.2 352.2-80.2 92.7 0 177 11.2 250.4 33.2 77.6 23.2 139.4 57.6 183.9 102 52.6 52.8 79.2 118.3 79.1 194.7a297 297 0 0 1-39.6 151.7A327.7 327.7 0 0 1 996.4 759a527.2 527.2 0 0 1-160 66.2 814.8 814.8 0 0 1-179.5 22v1a816.2 816.2 0 0 0 179.6-22A528.1 528.1 0 0 0 997 759.8c46.9-30.1 83.3-67 108.2-109.6A298 298 0 0 0 1145 498c.1-76.7-26.6-142.4-79.4-195.3Z'
      opacity={0.5}
    />
    <path
      fill='url(#F)'
      d='M1077 289.5c-45.7-45.8-109.4-81.1-189.2-105-75.6-22.5-162.2-34-257.6-34-148.4 0-273.6 28.4-362 82.2-47 28.5-83.2 64-107.9 105.5a291.4 291.4 0 0 0-39.3 151C121 600.5 173.6 693 273 757a545.6 545.6 0 0 0 165 68.5 832.5 832.5 0 0 0 184 22.9v-1c-65.3-1.2-127-8.8-183.8-22.8A544.3 544.3 0 0 1 273.6 756C174.4 692.3 122 600.1 122 489.3c0-56.2 13.2-106.9 39.1-150.6 24.6-41.4 60.8-76.8 107.6-105.2 88.2-53.7 213.3-82 361.5-82 95.3 0 181.9 11.4 257.3 34 79.7 23.8 143.3 59 188.9 104.7 53.9 53.9 81.2 121 81 199.3 0 57.1-13.7 109.4-40.7 155.3a336.5 336.5 0 0 1-110.7 112 542.1 542.1 0 0 1-164.4 68c-57 13.9-119 21.5-184.6 22.6v1a838.7 838.7 0 0 0 184.8-22.8 543 543 0 0 0 164.7-68c48.1-30.9 85.5-68.7 111-112.3 27-46.1 40.8-98.5 41-155.8 0-78.6-27.4-145.9-81.4-200Z'
      opacity={0.5}
    />
    <path
      fill='url(#G)'
      d='M1128.7 325.8c-27-42.4-66.5-78.7-117.5-108.1-48.2-27.8-106.2-49-172.5-63.3a998.6 998.6 0 0 0-208.8-20.6c-152.3 0-280.7 29-371.3 84-48.2 29.2-85.4 65.5-110.7 108-26.7 45-40.3 97-40.3 154.9 0 113.7 54 208.5 156 274a560.4 560.4 0 0 0 169.2 70.1 856 856 0 0 0 189.1 23.5v-1c-67-1.1-130.5-9-189-23.4a559.2 559.2 0 0 1-168.8-70c-101.8-65.3-155.6-159.8-155.6-273.2 0-57.7 13.5-109.6 40.2-154.4C173.9 284 211 247.7 259 218.6c90.5-55 218.8-84 371-84 74.6 0 144.8 7 208.5 20.7 66.2 14.2 124.2 35.5 172.3 63.2 50.8 29.3 90.2 65.6 117.1 107.8 28.7 45 43.2 97 43.1 154.5a310 310 0 0 1-41.8 159 345 345 0 0 1-113.6 114.8A557 557 0 0 1 846.9 824a861.2 861.2 0 0 1-190 23.3v1c67.7-1.2 131.5-9 190.2-23.4a558.1 558.1 0 0 0 169-69.6 346 346 0 0 0 114-115 311 311 0 0 0 41.8-159.5c0-57.7-14.5-109.9-43.2-155Z'
      opacity={0.5}
    />
    <path
      fill='url(#H)'
      d='M1141 313.5c-27.6-43.4-68.2-80.7-120.4-110.7-49.4-28.4-109-50.2-177-64.7-65.3-14-137.3-21-214-21-156.2 0-287.8 29.6-380.7 85.8-49.3 29.8-87.5 67-113.4 110.6-27.4 46-41.3 99.3-41.3 158.6 0 116.4 55.3 213.4 159.9 280.4a575 575 0 0 0 173.5 71.7 879.9 879.9 0 0 0 194.4 24v-.8c-69-1.2-134.2-9.3-194.2-24a574 574 0 0 1-173.2-71.6C150.2 684.8 95 588.2 95 472c0-59.1 13.8-112.3 41.1-158.1 25.9-43.4 64-80.6 113.2-110.3 92.7-56.1 224.2-85.8 380.3-85.8 76.6 0 148.5 7.1 213.8 21 67.9 14.6 127.3 36.4 176.7 64.7 52.1 30 92.5 67 120.1 110.4a288.9 288.9 0 0 1 44.3 158.2c-.1 59.8-14.5 114.5-42.9 162.7-26.8 45.6-66 85.1-116.5 117.4-48.6 31.1-106.8 55-173 71.3A884.6 884.6 0 0 1 657 847.4v1a885.2 885.2 0 0 0 195.4-24c66.3-16.2 124.6-40.2 173.3-71.3 50.5-32.4 89.9-72 116.8-117.7a317.6 317.6 0 0 0 43-163.2 290 290 0 0 0-44.5-158.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#I)'
      d='M1153.4 301.1c-28.3-44.4-69.9-82.5-123.4-113.2-50.6-29-111.7-51.2-181.3-66.1a1056 1056 0 0 0-219.3-21.5c-160.2 0-295 30.3-390.1 87.7-50.6 30.5-89.7 68.6-116.3 113.1-28 47-42.3 101.7-42.3 162.4 0 119 56.7 218.3 163.8 286.9 50 32 109.8 56.6 177.8 73.3A903.3 903.3 0 0 0 622 848.3v-1a902.3 902.3 0 0 1-199.4-24.5c-68-16.6-127.6-41.3-177.6-73.2C138.1 681.2 81.6 582.2 81.6 463.5c0-60.5 14.2-115 42.2-162 26.5-44.3 65.5-82.3 116-112.7 94.8-57.4 229.6-87.6 389.6-87.6 78.5 0 152.2 7.2 219.1 21.5 69.6 14.8 130.5 37 181 66 53.5 30.6 95 68.6 123.2 112.9a295.3 295.3 0 0 1 45.4 162c0 61.1-14.9 117-44 166.3-27.5 46.7-67.7 87.1-119.4 120.2-49.8 31.8-109.4 56.3-177.3 72.9A907.1 907.1 0 0 1 657 847.4v.9a908.1 908.1 0 0 0 200.6-24.4c68-16.7 127.7-41.2 177.6-73 51.8-33.2 92.1-73.7 119.7-120.5a324 324 0 0 0 44-166.8c0-60.5-15.2-115.2-45.5-162.5Z'
      opacity={0.5}
    />
    <path
      fill='url(#J)'
      d='M1165.8 288.7c-29-45.4-71.6-84.3-126.4-115.6-51.9-29.7-114.4-52.4-185.7-67.6-68.6-14.6-144.1-22-224.6-22-164 0-302.2 31-399.5 89.6-51.8 31.2-91.8 70.1-119 115.7-28.8 48-43.4 104-43.4 166 0 121.8 58 223.2 167.8 293.4 51.2 32.6 112.4 57.9 182 75a926.8 926.8 0 0 0 205 25v-.8a925.8 925.8 0 0 1-204.7-25.1c-69.6-17-130.7-42.3-181.8-74.9C126 677.4 68.1 576.3 68.1 454.8c0-61.9 14.6-117.6 43.2-165.6 27.1-45.4 67.1-84.3 118.8-115.4 97.1-58.5 235.1-89.4 399-89.4 80.4 0 156 7.4 224.4 22 71.3 15 133.7 37.8 185.5 67.4 54.7 31.3 97.1 70.1 126.1 115.4a301.8 301.8 0 0 1 46.5 165.7c0 62.5-15.2 119.7-45 170-28.2 47.7-69.3 89-122.3 122.9-51 32.5-112.1 57.6-181.7 74.6a930.1 930.1 0 0 1-205.6 25v.9a931 931 0 0 0 205.8-25c69.7-17 130.9-42.2 182-74.8 53-33.8 94.3-75.3 122.6-123.1a330.4 330.4 0 0 0 45-170.4c0-62-15.6-117.9-46.6-166.3Z'
      opacity={0.5}
    />
    <path
      fill='url(#K)'
      d='M1178.2 276.4c-29.7-46.4-73.3-86.2-129.4-118.2-53-30.3-117-53.5-190.1-69a1113.4 1113.4 0 0 0-229.9-22.4c-168 0-309.3 31.6-408.8 91.4-53 31.9-94 71.6-121.9 118.2-29.4 49.2-44.3 106.3-44.3 169.8 0 124.5 59.4 228.1 171.8 299.8 52.3 33.3 115 59.1 186.3 76.5a950.7 950.7 0 0 0 210 25.7v-.8c-74.5-1.2-145-9.8-209.8-25.7-71.2-17.4-133.8-43.1-186-76.4-112.2-71.5-171.4-175-171.4-299 0-63.4 14.8-120.4 44.2-169.5 27.7-46.4 68.6-86 121.5-117.9C319.8 99.2 461 67.6 628.8 67.6c82.4 0 159.6 7.6 229.7 22.4 73 15.5 136.9 38.7 189.9 69 56 31.9 99.4 71.5 129.1 117.8a308.5 308.5 0 0 1 47.6 169.5c0 63.8-15.5 122.2-46 173.7-28.8 48.8-71 91-125.3 125.6-52.2 33.2-114.7 58.9-186 76.2-65 16-135.9 24.5-210.8 25.6v.9c75-1.2 146-9.7 211-25.6 71.4-17.4 134-43.1 186.3-76.4 54.4-34.7 96.6-77 125.6-125.9a337 337 0 0 0 46-174c0-63.4-16-120.6-47.7-170Z'
      opacity={0.5}
    />
    <path
      fill='url(#L)'
      d='M1190.6 264c-30.4-47.4-75-88-132.4-120.7-54.3-31-119.8-54.6-194.5-70.4A1141.9 1141.9 0 0 0 628.6 50c-172 0-316.5 32.3-418.3 93.3-54.2 32.5-96.1 73.1-124.6 120.7-30 50.3-45.3 108.7-45.3 173.6 0 127.1 60.7 233 175.6 306.2 53.6 34 117.7 60.4 190.6 78.2A974 974 0 0 0 622 848.2v-.8a973.2 973.2 0 0 1-215-26.2c-72.9-17.8-137-44-190.4-78.1C101.8 670 41.2 564.4 41.2 437.6c0-64.8 15.2-123 45.2-173.2 28.4-47.4 70.3-88 124.4-120.4 101.6-61 246-93.2 417.8-93.2 84.2 0 163.2 7.7 235 22.9 74.6 15.8 140 39.4 194.2 70.3 57.3 32.6 101.8 73.1 132.1 120.4a315 315 0 0 1 48.8 173.3c0 65-15.9 124.7-47 177.3-29.6 49.8-72.8 93-128.3 128.3-53.4 34-117.4 60.2-190.3 78A976 976 0 0 1 657 847.4v.8c76.9-1 149.6-9.9 216.3-26.1 73-17.8 137-44 190.5-78.1 55.7-35.4 98.9-78.7 128.5-128.6a343.6 343.6 0 0 0 47.2-177.7c0-64.7-16.5-123.2-48.9-173.7Z'
      opacity={0.5}
    />
    <path
      fill='url(#M)'
      d='M1203 251.6c-31.1-48.4-76.7-89.8-135.5-123.2C1012 97 945.1 72.7 868.8 56.6a1170.8 1170.8 0 0 0-240.4-23.3c-175.8 0-323.7 32.9-427.6 95.1-55.5 33.2-98.3 74.7-127.4 123.2C42.5 303 26.9 362.6 26.9 429c0 129.8 62.1 237.9 179.6 312.6 54.8 34.8 120.4 61.6 195 79.8a998 998 0 0 0 220.4 26.8v-.8a996.7 996.7 0 0 1-220.3-26.8c-74.5-18.1-140-45-194.6-79.7C89.7 666.4 27.7 558.5 27.7 429c0-66.2 15.6-125.7 46.3-177 29-48.4 71.8-89.8 127-122.9 104-62.2 251.6-95 427.3-95 86.1 0 167 7.8 240.2 23.3 76.4 16 143.2 40.2 198.7 71.7 58.6 33.3 104 74.7 135.1 123a321.4 321.4 0 0 1 49.9 177c0 66.4-16.2 127.3-48.1 181-30.3 50.8-74.4 94.9-131.1 131-54.7 34.7-120.1 61.5-194.6 79.6A999.4 999.4 0 0 1 657 847.5v.8c78.7-1.2 153.2-10.2 221.6-26.8 74.5-18.1 140-45 194.8-79.7 56.9-36.2 101-80.4 131.4-131.3A350 350 0 0 0 1253 429c0-66.2-16.8-125.9-50-177.5Z'
      opacity={0.5}
    />
    <path
      fill='url(#N)'
      d='M1215.4 239.3c-31.8-49.4-78.4-91.7-138.5-125.8-56.7-32.1-125-56.8-203.2-73.2C798.7 24.5 716 16.5 628 16.5c-179.7 0-330.8 33.6-437 97-56.6 33.9-100.4 76.2-130.2 125.8-31.4 52.4-47.4 113.3-47.4 181 0 68 16.6 130.2 49.3 185 31 52 76.2 97.2 134.3 134 56 35.6 123 63 199.2 81.6a1022 1022 0 0 0 225.7 27.3v-.8c-80-1.2-155.9-10.4-225.5-27.3-76.1-18.5-143-46-199-81.4C139.5 702 94.4 657 63.5 605a355.4 355.4 0 0 1-49.2-184.6c0-67.7 16-128.5 47.3-180.7 29.7-49.5 73.4-91.7 130-125.5 106-63.4 257-96.9 436.5-96.9 88 0 170.7 8 245.5 23.7 78 16.5 146.4 41 203 73.2 60 34 106.5 76.2 138.2 125.5a327.9 327.9 0 0 1 51 180.7c0 67.8-16.5 130-49.2 184.7-30.8 51.9-76 96.9-134 133.7-55.8 35.4-122.8 62.8-198.9 81.3A1022 1022 0 0 1 657 847.4v.8c80.6-1.1 156.9-10.3 227-27.3 76.1-18.6 143.1-46 199-81.4 58.2-37 103.4-82 134.3-134a356.4 356.4 0 0 0 49.3-185c0-67.6-17.2-128.6-51.1-181.2Z'
      opacity={0.5}
    />
    <path
      fill='url(#O)'
      d='M1227.8 227.1c-32.5-50.4-80.1-93.5-141.5-128.2-58-32.8-127.8-58-207.6-74.7-76.5-16-161-24.2-251-24.2-183.6 0-338 34.2-446.3 98.9-57.9 34.5-102.6 77.6-133 128.2C16.3 280.6 0 342.8 0 412.1a363 363 0 0 0 50.3 188.6c31.7 53 77.8 99 137.2 136.7 57.2 36.2 125.6 64.2 203.5 83.1 71.3 17.4 149 26.8 231 28v-.9c-82-1.2-159.6-10.5-230.9-27.8-77.7-19-146-46.8-203.2-83-59.3-37.6-105.3-83.5-137-136.5A362.2 362.2 0 0 1 .9 412C.8 343 17 281 49 227.5c30.3-50.4 75-93.5 132.7-128C290 35 444.2.8 627.8.8 717.6.8 802 8.9 878.4 25c79.8 16.7 149.6 41.8 207.5 74.5 61.2 34.7 108.7 77.7 141.1 128 34.6 53.6 52.1 115.7 52.1 184.5 0 69.1-16.9 132.5-50.2 188.3-31.5 53-77.6 98.9-137 136.5-57 36.1-125.3 64-203 83a1044.6 1044.6 0 0 1-232 27.9v.8c82.4-1.2 160.4-10.6 232-28 78-19 146.4-46.9 203.5-83.1 59.4-37.7 105.6-83.6 137.2-136.7A363 363 0 0 0 1280 412c0-69-17.6-131.2-52.2-184.9Z'
      opacity={0.5}
    />
    <defs>
      <linearGradient id='a' x1={638.8} x2={638.8} y1={669.7} y2={848.1} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      {/* <linearGradient id='b' x1={638.9} x2={638.9} y1={652.9} y2={848.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='c' x1={638.9} x2={638.9} y1={636.2} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='d' x1={638.9} x2={638.9} y1={619.4} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='e' x1={638.9} x2={638.9} y1={602.7} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='f' x1={639} x2={639} y1={585.9} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='g' x1={639} x2={639} y1={569.2} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='h' x1={639} x2={639} y1={552.5} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='i' x1={639.1} x2={639.1} y1={535.7} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop  stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='j' x1={639.1} x2={639.1} y1={519} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='k' x1={639.1} x2={639.1} y1={502.2} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='l' x1={639.1} x2={639.1} y1={485.5} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='m' x1={639.2} x2={639.2} y1={468.7} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='n' x1={639.2} x2={639.2} y1={452} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='o' x1={639.2} x2={639.2} y1={435.2} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='p' x1={639.3} x2={639.3} y1={418.5} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='q' x1={639.3} x2={639.3} y1={401.7} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='r' x1={639.3} x2={639.3} y1={385} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='s' x1={639.3} x2={639.3} y1={368.2} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='t' x1={639.4} x2={639.4} y1={351.5} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='u' x1={639.4} x2={639.4} y1={334.7} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='v' x1={639.4} x2={639.4} y1={318} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='w' x1={639.5} x2={639.5} y1={301.2} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='x' x1={639.5} x2={639.5} y1={284.5} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='y' x1={639.5} x2={639.5} y1={267.7} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='z' x1={639.6} x2={639.6} y1={251} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='A' x1={639.6} x2={639.6} y1={234.2} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='B' x1={639.6} x2={639.6} y1={217.5} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='C' x1={639.7} x2={639.7} y1={200.8} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='D' x1={639.7} x2={639.7} y1={184} y2={848.4} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='E' x1={639.7} x2={639.7} y1={167.3} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='F' x1={639.7} x2={639.7} y1={150.5} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='G' x1={639.8} x2={639.8} y1={133.8} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='H' x1={639.8} x2={639.8} y1={117} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='I' x1={639.8} x2={639.8} y1={100.3} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='J' x1={639.9} x2={639.9} y1={83.5} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='K' x1={639.9} x2={639.9} y1={66.8} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='L' x1={639.9} x2={639.9} y1={50} y2={848.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='M' x1={639.9} x2={639.9} y1={33.3} y2={848.3} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='N' x1={640} x2={640} y1={16.5} y2={848.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='O' x1={640} x2={640} y1={0} y2={848.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D8B77C' />
        <stop offset={0.9} stopColor='#D8B77C' stopOpacity={0} />
      </linearGradient> */}
    </defs>
  </svg>
)

const Waves = memo(SvgComponent)
export default Waves
