import { React, useState } from 'react'
import gsap from 'gsap'
import Tilte from '../title/Title'
import MobileMenu from '../mobile-menu/Mobile-menu'

import { Link } from 'react-router-dom'
import './header.css'

const Header = ({ setActive, title, temp }) => {
  const [count, setCount] = useState(0)
  const [open, setOpen] = useState(false)

  const openHandler = () => {
    setOpen(!open)
  }

  const scrolToTop = () => {
    gsap.to('.nft__container', { duration: 2, scrollTo: { y: '#section1' } })
    setActive(0)
  }

  return (
    <>
      <div className='header section__padding'>
        <nav className='navbar'>
          <span className='navbar__item'>
            <a href='https://domworld.io/' target='_blank' rel='noreferrer noopener'>
              DOM WORLD
            </a>
            {/* <Tilte initialValue={'DOM WORLD'} newValue={'NEW TITLE'} /> */}
          </span>
          <span className='navbar__item'>
            {/* DEPARTMENT */}
            <Tilte initialValue={'DEPARTMENT'} newValue={'MIND WORKING'} />
          </span>
          <span className='navbar__item'>
            {/* COMMUNITY */}
            <Tilte initialValue={'COMMUNITY'} newValue={'MIND WORKING'} />
          </span>
          <span className='navbar__item'>
            {/* MARKET */}
            <Tilte initialValue={'MARKET'} newValue={'MIND WORKING'} />
          </span>
          <span className='navbar__item'>
            {/* CONTACT */}
            <Tilte initialValue={'CONTACT'} newValue={'MIND WORKING'} />
          </span>
        </nav>

        {count < 28 && (
          <svg
            onClick={() => {
              setCount(count + 1)
            }}
            width='64'
            height='57'
            viewBox='0 0 64 57'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='logo'
          >
            <path
              d='M36.8588 45.6218L37.9974 45.3609C50.8345 42.5605 53.2265 26.6451 47.5034 17.5389C44.998 13.5523 41.0899 11.5062 36.5412 10.8017C32.4527 10.1687 28.3536 10.2031 24.4026 11.6677C17.7291 14.1415 14.4923 19.2698 13.9364 26.1438C13.4943 31.6102 14.5141 36.7769 18.4449 40.9661C20.451 43.1042 23.101 44.4087 25.7159 45.2221L27.1002 45.6222V54.9794C25.1677 54.6346 23.5087 54.4364 21.9002 54.0346C13.3869 51.9093 6.31269 47.6561 3.20918 39.004C-0.597165 28.3908 0.174948 18.207 8.20733 9.55355C12.3574 5.08256 17.8355 2.8774 23.7165 1.83381C32.2017 0.328082 40.5661 0.680665 48.5302 4.39391C57.8682 8.7476 62.4704 16.3204 62.9541 26.5096C63.2894 33.5774 61.8359 40.1787 57.0452 45.6448C51.7777 51.6555 44.8049 53.9888 36.8588 55V45.6218Z'
              strokeWidth='2'
              strokeMiterlimit='10'
            />
          </svg>
        )}
        {count === 28 && (
          <Link to={'birthday'}>
            <svg width='64' height='57' viewBox='0 0 64 57' fill='none' xmlns='http://www.w3.org/2000/svg' className='logo'>
              <path
                d='M36.8588 45.6218L37.9974 45.3609C50.8345 42.5605 53.2265 26.6451 47.5034 17.5389C44.998 13.5523 41.0899 11.5062 36.5412 10.8017C32.4527 10.1687 28.3536 10.2031 24.4026 11.6677C17.7291 14.1415 14.4923 19.2698 13.9364 26.1438C13.4943 31.6102 14.5141 36.7769 18.4449 40.9661C20.451 43.1042 23.101 44.4087 25.7159 45.2221L27.1002 45.6222V54.9794C25.1677 54.6346 23.5087 54.4364 21.9002 54.0346C13.3869 51.9093 6.31269 47.6561 3.20918 39.004C-0.597165 28.3908 0.174948 18.207 8.20733 9.55355C12.3574 5.08256 17.8355 2.8774 23.7165 1.83381C32.2017 0.328082 40.5661 0.680665 48.5302 4.39391C57.8682 8.7476 62.4704 16.3204 62.9541 26.5096C63.2894 33.5774 61.8359 40.1787 57.0452 45.6448C51.7777 51.6555 44.8049 53.9888 36.8588 55V45.6218Z'
                strokeWidth='2'
                strokeMiterlimit='10'
              />
            </svg>
          </Link>
        )}
      </div>
      <div className='header-mobile section__padding'>
        {open ? (
          <h2 className={`header-mobile__title ${open ? 'active' : 'prev'}`}>welcome</h2>
        ) : (
          <h2 className={`header-mobile__title ${open ? 'prev' : 'active'}`} onClick={scrolToTop}>
            {title}&nbsp;&nbsp;DNA
          </h2>
        )}
        <button className='header-mobile__button' onClick={openHandler}>
          <span className={`header-mobile__button-item ${open ? 'header-mobile__button-item_opened' : ''}`}></span>
          <span className={`header-mobile__button-item ${open ? 'header-mobile__button-item_opened' : ''}`}></span>
          <span className={`header-mobile__button-item ${open ? 'header-mobile__button-item_opened' : ''}`}></span>
        </button>
      </div>
      <MobileMenu className={open ? 'mobile-menu__container_opened' : ''} />
    </>
  )
}

export default Header
